import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import { Auth } from 'aws-amplify';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import LogoIReferIcon from '../../../assets/images/icon-logo-irefer-trademark.png';
import AppleLogo from '../../../assets/images/icon-apple.png';
import GoogleLogo from '../../../assets/images/icons8-google-48.png';
import { StyledBox } from '../../../components/Common/Styled/CommonStyled';
import {
  CommonSubtext,
  CommonSubtextLink,
  CommonTitleText,
} from '../../../components/Common/Styled/TypographyStyed';
import FormikControl, {
  CONTROL_TYPE,
} from '../../../components/Formik/FormikControl';
import { useSignUp } from '../../../hooks/Auth/useAuth';
import {
  Logo,
  StyledLoadingButton,
  StyledStack,
  TextButton,
} from '../AuthStyled';
import commonConstants from 'constants/common.constant';
import { themes } from 'theme';
import ReCAPTCHA from 'react-google-recaptcha';

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY || '';

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Not a valid email address')
    .required('Username / Email Address is required'),
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Check the password requirements below',
    )
    .required('Check the password requirements below'),
  agreeTerm: Yup.boolean().oneOf(
    [true],
    'You must agree to user terms and conditions and Privacy Policy.',
  ),
  recaptcha: Yup.string().required('Please verify you are a human'),
});

type LoginValues = {
  email: string;
  password: string;
  agreeTerm: boolean;
  recaptcha: string | null;
};

const initialValues: LoginValues = {
  email: '',
  password: '',
  agreeTerm: false,
  recaptcha: null,
};

const SignUp = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { mutate: mutateSignUp, isLoading: isLoadingSignUp } = useSignUp();
  const isLoading = useMemo(() => isLoadingSignUp, [isLoadingSignUp]);

  const onSubmit = (values: LoginValues) => {
    if (!values.recaptcha) {
      // Handle the case where the reCAPTCHA was not completed
      return;
    }
    mutateSignUp(values);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const {
    handleSubmit,
    errors,
    validateField,
    values,
    setFieldValue,
    isValid,
    dirty,
  } = formik;
  const continueWithSocialMedia = async (
    type: typeof commonConstants.SOCIAL_MEDIA[keyof typeof commonConstants.SOCIAL_MEDIA],
  ) => {
    try {
      await validateField('agreeTerm');
      if (errors?.agreeTerm || !values?.agreeTerm) return;
      switch (type) {
        case commonConstants.SOCIAL_MEDIA.APPLE:
          Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Apple,
          });
          break;
        case commonConstants.SOCIAL_MEDIA.GOOGLE:
          Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Google,
          });
          break;
        default:
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <Helmet>
          <title>Sign up Vendor App</title>
          <meta name="description" content="iRefer Sign up" />
        </Helmet>
        <StyledBox>
          <StyledStack>
            <Stack alignItems="center" mb={2}>
              <Logo
                alt="iReferLogo"
                src={LogoIReferIcon}
                style={{ width: '104px' }}
              />
            </Stack>
            <CommonTitleText>CREATE A VENDOR ACCOUNT</CommonTitleText>
            <Stack mt="16px" mb="10px" gap={3}>
              <FormikControl
                autoCapitalize="none"
                control={CONTROL_TYPE.INPUT}
                name="email"
                label="Username / Email Address"
                InputLabelProps={{ shrink: true }}
              />
              <FormikControl
                autoCapitalize="none"
                control={CONTROL_TYPE.PASSWORD}
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowPassword((prevState) => !prevState)
                        }
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <FormControl required component="fieldset" variant="standard">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: themes.light.colorBlack,
                        '&.Mui-checked': {
                          color: themes.light.colorBlack,
                        },
                      }}
                      checked={values.agreeTerm}
                      onChange={(e: any) => {
                        setFieldValue(
                          'agreeTerm',
                          e.target.checked,
                          true /* shouldValidate */,
                        );
                      }}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        '& a': {
                          color: themes.light.colorMaximumBlueGreen,
                        },
                      }}
                    >
                      I agree to user{' '}
                      <Typography
                        component="a"
                        href={
                          commonConstants.MERCHANT_TERMS_AND_CONDITIONS_LINK
                        }
                        target="_blank"
                      >
                        Terms and Conditions
                      </Typography>{' '}
                      and{' '}
                      <Typography
                        component="a"
                        href={commonConstants.PRIVACY_POLICY_LINK}
                        target="_blank"
                      >
                        Privacy Policy.
                      </Typography>
                    </Typography>
                  }
                />
              </FormGroup>
            </FormControl>
            <ReCAPTCHA
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={(value: string | null) =>
                setFieldValue('recaptcha', value)
              }
            />
            <StyledLoadingButton
              loading={isLoading}
              variant="contained"
              disabled={!(isValid && dirty)}
              onClick={() => {
                handleSubmit();
              }}
            >
              Create Account
            </StyledLoadingButton>
            <StyledLoadingButton
              disabled={!values?.agreeTerm || !values.recaptcha}
              loading={isLoading}
              variant="contained"
              onClick={() =>
                continueWithSocialMedia(commonConstants.SOCIAL_MEDIA.APPLE)
              }
              // sx={{ marginBottom: '.5rem' }}
              startIcon={
                <Logo
                  alt="AppleLogo"
                  src={AppleLogo}
                  style={{ width: '24px' }}
                />
              }
            >
              Continue with Apple
            </StyledLoadingButton>
            <StyledLoadingButton
              disabled={!values?.agreeTerm || !values.recaptcha}
              loading={isLoading}
              variant="contained"
              onClick={() =>
                Auth.federatedSignIn({
                  provider: CognitoHostedUIIdentityProvider.Google,
                })
              }
              startIcon={
                <Logo
                  alt="GoogleLogo"
                  src={GoogleLogo}
                  style={{ width: '20px' }}
                />
              }
            >
              Continue with Google
            </StyledLoadingButton>
            <CommonSubtext>
              Already have a Vendor account?
              <CommonSubtextLink as="span">
                <TextButton
                  onClick={() => navigate('/auth/login')}
                  variant="text"
                  disabled={isLoading}
                >
                  Login
                </TextButton>
              </CommonSubtextLink>
            </CommonSubtext>
          </StyledStack>
        </StyledBox>
      </Form>
    </FormikProvider>
  );
};

export default SignUp;
